import React from 'react'
import { PageProps, graphql } from 'gatsby'

import { YearlyArchivesQuery } from '../../graphql-types'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PostArchive from '../components/organisms/PostArchive'

const YearlyArchives: React.FC<PageProps<YearlyArchivesQuery>> = ({ data, pageContext }) => {
  const { year } = pageContext
  const posts = data.allMarkdownRemark.nodes
  return (
    <Layout>
      <SEO title="comilablog" />
      <PostArchive year={year} posts={posts} />
    </Layout>
  )
}

export default YearlyArchives

export const pageQuery = graphql`
  query YearlyArchives ($from: Date!, $to: Date!) {
    allMarkdownRemark (
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {date: {gte: $from, lt: $to}}
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
        }
      }
    }
  }
`
